import { configureStore } from '@reduxjs/toolkit';
import userSlice from './features/user/userSlice';
//import apartmentSlice from './features/apartmentSlice';

export const bookStore = () => {
  return configureStore({
    reducer: {
      userSesssion: userSlice,
      // apartment: apartmentSlice,
    },
  });
};

export type AppStore = ReturnType<typeof bookStore>;

export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];