import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  floor: number | null;
  unit: string;
  language: string | null;
  selectedThemeName: string;
  selectedThemeNameLS: string | null;
  isDarkTheme: boolean | null;
}

const initialState: UserState = {
  floor: null,
  unit: '',
  language: typeof window !== "undefined" ? ( window.localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'de' ) : 'de',
  selectedThemeName: '',
  selectedThemeNameLS: typeof window !== "undefined" ? ( window.localStorage.getItem('theme') ? localStorage.getItem('theme') : 'dark' ) : 'dark',
  isDarkTheme: typeof window !== "undefined" ? ( window.localStorage.getItem('isDarkTheme') === "light" ? false : true ) : null,
};

export const userSlice = createSlice({
  name: 'userSesssion',
  initialState,
  reducers: {
    updateFloor: (state, action: PayloadAction<number>) => {
      state.floor = action.payload;
    },
    updateUnit: (state, action: PayloadAction<string>) => {
      state.unit = action.payload;
    },
    updateSelectedTheme: (state, action: PayloadAction<string>) => {
      // Set the value in localStorage
      window.localStorage.setItem('theme', action.payload);
      window.localStorage.setItem('isDarkTheme', action.payload === "light" ? "false" : "true");
      state.selectedThemeNameLS = action.payload;
      state.isDarkTheme = action.payload === "light" ? false : true;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    }
  },
});

export const { updateFloor, updateUnit,updateSelectedTheme, setLanguage } = userSlice.actions;
export default userSlice.reducer;